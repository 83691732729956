:root {
	--white-color: #FFF;
	--red-color: #FF0000;
	--red-light-color: #F9172C;
	--green-color: #0E910A;
	--green-light-color: #A9C23F;
	--grey-color: #D8D9D9;
	--light-grey-color: #E5E5E5;
	--silver-color: #6C757D;
	--black-color: #000;
	--black-grey-color: #222;
	--primary-color: #17366E;
	--secondary-color: #1D4288;
	--primary-special-color: #EE7C00;
	--secondary-special-color: #EE7C00;
	--default-font-color: var(--primary-color);
	--default-hover-color: var(--primary-special-color);
	--default-font-bright-color: var(--white-color);
	--default-border-color: var(--grey-color);
	--default-background-color: #EBEBEB;
	--default-error-color: var(--red-color);
	--default-success-color: var(--green-color);
	--alert-success-color: var(--green-light-color);
	--alert-error-color: var(--red-light-color);
}


$primaryColor: var(--primary-color);
$primarySpecialColor: var(--primary-special-color);

$white: var(--white-color);
$black: var(--black-color);
$black-grey: var(--black-grey-color);
