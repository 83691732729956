.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__team-files {
				
				&__files-list {
					
					&__add-file {
						
						&__form {
							
							margin: 15px 0;
							padding: 0 30px;
							
							&__content {
								align-content: center;
								align-items: center;
							}
							
							.form-field {
								margin-bottom: 0;
							}
							
							.information-form-field {
								line-height: 15px;
							}
							
							.submit-form-field {
								.form-field-content {
									justify-content: right;
								}
							}
							
							.system-message-component {
								margin-top: 15px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
