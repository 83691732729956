.team-details {
	
	&__team-information {
		
		&__team-top-players {
			
			&__list {
				
				&__header {
					
					background: #E6E6E6;
					border-bottom: 2px solid var(--secondary-color);
					
					&__content {
						justify-content: space-between;
					}
					
					&__option {
						
						padding: 3px 5px;
						font-size: 11px;
						text-transform: uppercase;
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						padding: 5px 10px;
						font-size: 12px;
						}
						
						&.active {
							color: #FFFFFF;
							background: var(--secondary-color);
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
