.game-result-game-information {
	
	margin-top: 10px;
	font-weight: 600;
	text-align: center;
	
	@media screen and (min-width: 992px) {
	position: absolute;
	bottom: 0;
	height: 50px;
	margin-top: 0;
	}
	
	.game-result-information-content {
		@media screen and (min-width: 992px) {
		margin: 0 -10px 0;
		}
	}
	
	.result-information-extra-time,
	.result-information-game-round-number,
	.result-information-game-date {
		
		position: relative;
		padding: 0;
		border: 1px solid var(--default-border-color);
		border-left: none;
		
		@media screen and (min-width: 992px) {
		border-color: #FFF;
		}
		
		&:first-child {
			border-left: 1px solid var(--default-border-color);
		}
		
		.result-information-label,
		.result-information-value {
			display: block;
			min-height: 24px;
			line-height: 24px;
			@media screen and (min-width: 992px) {
			min-height: 25px;
			line-height: 25px;
			}
		}
		
		.result-information-label {
			
			color: #FFF;
			font-size: 11px;
			background: var(--secondary-color);
			
			@media screen and (min-width: 992px) {
			font-size: 12px;
			}
			
		}
		
		.result-information-value {
			
			font-size: 13px;
			@media screen and (min-width: 992px) {
			font-size: 15px;
			}
			
			.winner {
				font-weight: 700;
			}
			
		}
		
		.checkbox {
			position: absolute;
			top: 5px;
			right: 7px;
			width: 13px;
			height: 13px;
			border: 1px solid #CBCBCB;
			cursor: pointer;
			
			@media screen and (min-width: 992px) {
			right: 5px;
			width: 15px;
			height: 15px;
			}
			
			&.checked {
				background: #FFF;
			}
			
			svg {
				vertical-align: top;
				width: 11px;
				height: 11px;
				color: #017A3B;
				@media screen and (min-width: 992px) {
					width: 13px;
					height: 13px;
				}
			}
			
		}
		
		&.disabled {
			.checkbox {
				background: #ADB5BD;
				svg {}
				cursor: not-allowed;
			}
		}
		
	}
	
}
