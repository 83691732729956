.team-header-logo {
	
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding: 5px;
	background: #FFF;
	border-left: 1px solid var(--default-border-color);
	border-bottom: 1px solid var(--default-border-color);
	
	@media screen and (min-width: 992px) {
	position: absolute;
	top: 0;
	left: -100px;
	width: 100px;
	max-width: 100px;
	height: 100px;
	padding: 10px;
	border: none;
	border-left: none;
	border-bottom: 5px solid var(--default-border-color);
	}
	
	a {
		
		display: block;
		
		img {
			max-height: 35px;
			@media screen and (min-width: 992px) {
			max-height: 100%;
			}
		}
		
		&:hover {
			opacity: 0.7;
		}
		
	}
	
}
