.game-result-details {
	
	&__diagrams {
		
		position: relative;
		padding: 0 20px;
		
		@media screen and (min-width: 992px) {
		padding: 0 15px;
		}
		
	}
	
}
