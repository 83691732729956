#contact-page-component {
	
	margin: 30px 0 20px;
	padding: 0;
	text-transform: uppercase;
	
	.main-section-header {
		margin: 0 0 20px;
		@media screen and (min-width: 992px) {
			padding: 0;
		}
	}
	
	.contact-page-content {
		
		text-align: center;
		padding: 25px;
		background: var(--primary-color);
		@media screen and (min-width: 992px) {
			text-align: left;
			min-height: 300px;
			padding: 50px;
			background-size: cover;
		}
		
		.contact-page-information {
			margin-bottom: 20px;
			@media screen and (min-width: 992px) {
				margin-bottom: 0;
			}
			span, a {
				color: var(--white-color);
				@media screen and (min-width: 992px) {
					font-size: 16px;
				}
			}
			span {
				margin-right: 5px;
			}
			a {
				&:hover {
					color: var(--primary-special-color);
				}
			}
		}
		
		.contact-page-form {
			
			.contact-form-header {
				margin-bottom: 20px;
				font-size: 16px;
				color: var(--white-color);
				@media screen and (min-width: 992px) {
					font-size: 20px;
				}
			}
			
			.contact-form-fields {
				.contact-form-submit {
					text-align: center;
				}
			}
			
		}
		
	}
	
}
