.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__team-files {
				
				&__files-list {
					
					margin-bottom: 20px;
					background: #FFF;
					border: 1px solid var(--default-border-color);
					
				}
				
			}
			
		}
		
	}
	
}
