.transfers-list {
	
	&__list {
		
		&__players {
			
			position: relative;
			margin: 10px 0;
			padding-top: 10px;
			border-top: 1px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin: 20px 0;
			padding-top: 20px;
			}
			
		}
		
	}
	
}
