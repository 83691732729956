.home-page {
	
	&__articles {
	
		margin: 0 0 10px;
		padding: 10px 15px 0;
		border-top: 2px solid #E5E5E5;
		
		@media screen and (min-width: 992px) {
		padding-top: 50px;
		}
	
		&:first-child {
			margin-top: 10px;
			padding-top: 0;
			border-top: none;
		}
		
	}
	
}


