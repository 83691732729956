.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
					
					&__team {
						
						margin-bottom: 10px;
						//border-top: 3px solid var(--primary-color);
						@media screen and (min-width: 992px) {
						padding: 5px 15px 0;
						background: var(--secondary-color);
						}
						
						&__content {
							align-content: center;
							align-items: center;
						}
						
						&__name, &__logo {}
						
						&__name {
							
							&__content {
								padding: 0 10px;
								justify-content: right;
							}
							
							a {
								
								font-size: 12px;
								font-weight: 600;
								line-height: 15px;
								
								@media screen and (min-width: 992px) {
								font-weight: 500;
								color: #FFF;
								}
								
								&:hover {
									color: var(--primary-special-color);
								}
								
							}
							
						}
						
						&__logo {
							
							display: inline-flex;
							
							&__content {
								width: calc(100% + 30px);
								padding: 0 10px 0 0;
								justify-content: center;
								align-items: center;
							}
							
							a {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								align-content: center;
								width: 100%;
								padding: 2px 5px;
								background: #FFF;
								&:hover {
									opacity: 0.7;
								}
							}
							
							img {
								max-height: 34px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}

	}

}
