.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			position: relative;
			
		}
		
	}
	
}
