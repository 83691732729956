.league-tables {
	
	&__group {
		
		&__table {
			
			&__team {
			
				position: relative;
				overflow: hidden;
				max-height: 40px;
				padding: 5px 15px;
				background: #FFF;
				
				@media screen and (min-width: 992px) {
				max-height: 50px;
				padding: 10px 15px;
				}
				
				&:nth-child(odd) {
					background: #E6E6E6;
				}
				
				.team-table-position,
				.team-logo,
				.team-name,
				.team-table-points,
				.team-played-games,
				.team-games-balance,
				.team-small-points-balance,
				.team-small-points-difference {
					
					position: relative;
					max-height: 30px;
					font-size: 10px;
					text-align: center;
					text-transform: uppercase;
					line-height: 30px;
					
					@media screen and (min-width: 992px) {
					font-size: 12px;
					}
					
				}
				
				.team-table-position {
					padding: 0;
					font-weight: 600;
				}
				
				.team-logo {
					
					padding: 0;
					
					a {
						display: block;
						max-height: 30px;
					}
					
					img {
						max-height: 30px;
					}
					
				}
				
				.team-table-points,
				.team-played-games,
				.team-games-balance,
				.team-small-points-balance,
				.team-small-points-difference {
					padding: 0;
				}
				
				.team-name {
					
					padding: 0 10px;
					display: flex;
					align-content: center;
					align-items: center;
					justify-content: left;
					font-weight: 600;
					line-height: 15px;
					
					a {
						display: block;
						overflow: hidden;
						max-height: 30px;
						text-align: left;
						line-height: 15px;
						
						span {
							text-align: left;
							line-height: 15px;
						}
						
					}
					
				}
				
				.team-table-points {
					
					.points-label {
						margin-left: 3px;
						@media screen and (min-width: 992px) {
						display: none;
						}
					}
					
				}
			
			}
			
		}

	}
}
