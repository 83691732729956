.team-details {
	
	&__team-information {
		
		&__team-next-games {
			
			&__games-list {
			
				@media screen and (min-width: 992px) {
				padding-left: 30px;
				}
				
			}
			
		}
		
	}
	
}
