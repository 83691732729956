

.text-button {
	
	display: inline-flex;
	align-items: center;
	align-content: center;
	cursor: pointer;
	
	&:hover {
		color: #EE7C00;
	}
	
	&__text {
		font-weight: 600;
	}
	
	&__icon {
		width: 16px;
		height: 16px;
		margin-top: -2px;
		margin-left: 5px;
	}
	
}


.inline-checkbox-element {
	
	display: inline-flex;
	width: 18px;
	height: 18px;
	align-items: center;
	align-content: center;
	justify-content: center;
	background: #FFFFFF;
	border: 1px solid #D8D9D9;
	
	svg {
		width: 12px;
		color: #1C7430;
	}
	
}
