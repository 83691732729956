.password-set-page {
	
	@media screen and (min-width: 992px) {
	background: #FFF;
	}
	
	.message-component {
		margin-bottom: 15px;
	}
	
}
