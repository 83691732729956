.game-result-quarter {
	
	position: relative;
	padding: 0;
	border: 1px solid var(--default-border-color);
	border-left: none;
	
	@media screen and (min-width: 992px) {
	border-color: #FFF;
	}
	
	&:first-child {
		border-left: 1px solid var(--default-border-color);
		@media screen and (min-width: 992px) {
		border-color: #FFF;
		}
	}
	
	.quarter-number,
	.quarter-result {
		display: block;
		min-height: 24px;
		line-height: 24px;
		@media screen and (min-width: 992px) {
		min-height: 25px;
		line-height: 25px;
		}
	}
	
	.quarter-number {
		color: #FFF;
		font-size: 11px;
		background: var(--secondary-color);
		padding-right: 10px;
		@media screen and (min-width: 992px) {
		padding-right: 15px;
		font-size: 12px;
		}
	}
	
	.quarter-result {
		font-size: 13px;
		@media screen and (min-width: 992px) {
		font-size: 15px;
		}
		.winner {
			font-weight: 700;
		}
	}
	
	.checkbox {
		position: absolute;
		top: 5px;
		right: 7px;
		width: 13px;
		height: 13px;
		border: 1px solid #CBCBCB;
		cursor: pointer;
		
		@media screen and (min-width: 992px) {
		right: 5px;
		width: 15px;
		height: 15px;
		}
		
		&.checked {
			background: #FFF;
		}
		
		svg {
			vertical-align: top;
			width: 11px;
			height: 11px;
			color: #017A3B;
			@media screen and (min-width: 992px) {
			width: 13px;
			height: 13px;
			}
		}
		
	}
	
	&.disabled {
		.checkbox {
			background: #ADB5BD;
			svg {}
			cursor: not-allowed;
		}
	}
	
}
