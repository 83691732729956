.team-details {
	
	&__team-information {
		
		&__team-top-players {
			
			&__list {
				
				position: relative;
				
				.message-component {
					margin-top: 10px;
				}
				
			}
			
		}
		
	}
	
}
