.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
					
					&__information {
						
						padding-left: 30px;
						
						@media screen and (min-width: 992px) {
						padding-left: 15px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
