.page-component-header-block {
	
	margin: 0;
	padding: 10px 0;
	border-bottom: 2px solid var(--primary-color);
	
	@media screen and (min-width: 992px) {
	padding: 15px 0;
	}
	
	@media screen and (min-width: 1366px) {
	padding: 20px 0;
	}
	
	.container {
		@media screen and (min-width: 1366px) {
		max-width: 1300px;
		}
	}
	
}
