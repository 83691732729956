.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__header {
					
					padding: 10px 30px;
					font-size: 14px;
					color: #FFF;
					background: var(--primary-color);
					cursor: pointer;
					
					@media screen and (min-width: 992px) {
					font-size: 20px;
					//padding: 10px 30px;
					}
					
					&:hover {
						opacity: 0.8;
					}
					
					&__content {
						justify-content: center;
					}
					
					svg {
						margin-top: 2px;
						margin-left: 10px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
