.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__information {
						
							&__league-season {
							
								font-weight: 600;
								
								&__season-label {
									margin-right: 5px;
								}
								
								&__active-status {
									margin-left: 5px;
									&.active {
										color: #15DB42;
									}
									&.inactive {
										color: #DA5E15;
									}
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
