.game-result-quarters {
	
	margin-top: 10px;
	font-weight: 600;
	text-align: center;
	
	@media screen and (min-width: 992px) {
	position: absolute;
	bottom: 0;
	height: 50px;
	margin-top: 0;
	}
	
	.game-result-quarters-content {
		@media screen and (min-width: 992px) {
		margin: 0 -10px 0;
		}
	}
	
}
