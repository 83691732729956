.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__information {
							
							&__league-name {
								
								font-size: 14px;
								font-weight: 600;
								
								&__name {}
								
								&__short-name {
									margin-left: 5px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
