.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
				
				&__group {
					
					&__table {
						
						position: relative;
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
