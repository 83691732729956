.game-result-details {
	
	&__progress {
		
		&__header {
			
			border-left: 1px solid var(--default-border-color);
			border-right: 1px solid var(--default-border-color);
			background: #FFF;
			
			@media screen and (min-width: 992px) {}
			
		}
		
	}
	
}
