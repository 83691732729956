.user-form {
	
	.form-field {
		
		position: relative;
		
		label:not(.error) {
			padding-right: 40px;
		}
		
		.form-label {
			margin-bottom: 5px;
			@media screen and (min-width: 992px) {}
		}
		
	}
	
	.form-group {
		margin-bottom: 10px;
	}
	
	.form-control {
		
		font-size: 12px;
		text-transform: uppercase;
		
		&::placeholder {
			font-size: 12px;
		}
		
		&[type="color"] {
			padding: 0;
		}
		
		&[type="file"] {
			color: transparent;
		}
		
	}
	
	.form-fields-separator {
		display: none;
		@media screen and (min-width: 992px) {
		display: block;
		margin: 10px 0 20px;
		border-bottom: 1px solid var(--secondary-color);
		}
	}
	
	.submit-form-field {
		text-align: center;
		.form-field-content {
			justify-content: center;
		}
	}
	
	.form-field-text {
		padding: 0;
		font-size: 13px;
		font-weight: 600;
		span {
			display: block;
			line-height: 24px;
			&.empty {
				font-weight: 500;
				color: var(--silver-color);
			}
		}
	}
	
	.form-field-actions {
		
		position: absolute;
		top: 0;
		right: 15px;
		
		&.active {
			position: relative;
			right: 0;
			margin-top: 5px;
		}
		
		.form-field-actions-content {
			justify-content: right;
			align-content: center;
			align-items: center;
		}
		
		.form-field-action {
			
			padding: 0 2px;
			
			span {
				cursor: pointer;
			}
			
			svg {
				width: 18px;
				height: 18px;
				vertical-align: top;
			}
			
			&.save-action, &.abort-action {
				
				display: inline-flex;
				align-content: center;
				align-items: center;
				justify-content: space-between;
				font-size: 9px;
				padding: 0 5px;
				color: var(--white-color);
				border-radius: 2px;
				line-height: 20px;
				
				@media screen and (min-width: 992px) {
					padding: 0 5px;
					font-size: 11px;
					line-height: 22px;
					border-radius: 5px;
				}
				
				svg {
					
					width: 14px;
					height: 14px;
					margin-top: 2px;
					margin-left: 2px;
					
					@media screen and (min-width: 992px) {
						width: 18px;
						height: 18px;
						margin-top: 2px;
						margin-left: 5px;
					}
					
				}
				
				
			}
			
			&.save-action {
				margin-left: 5px;
				background: var(--default-success-color);
				@media screen and (min-width: 992px) {
					margin-left: 10px;
				}
			}
			
			&.abort-action {
				background: var(--default-error-color)
			}
			
		}
		
	}
	
	.form-field-label {
		&.error {
			width: 100%;
			margin: 5px 0 0;
			text-align: center;
			justify-content: center;
		}
	}
	
}
