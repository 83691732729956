.home-page {
	
	&__articles {
		
		&__list {
			margin-bottom: 20px;
		}
		
		.slick-slider {
			width: 100%;
			text-align: center;
		}
		
		.slick-list {
			
			overflow: hidden;
			margin: 0 auto;
			width: 300px;
			
			@media screen and (min-width: 768px) {
			width: 600px;
			}
			
			@media screen and (min-width: 992px) {
			width: 900px;
			}
			
			@media screen and (min-width: 1300px) {
			width: 1200px;
			}
			
			@media screen and (min-width: 1600px) {
			width: 1500px;
			}
			
		}
		
		.slick-slide {
			width: 300px;
			max-width: 300px;
		}
		
	}
	
}
