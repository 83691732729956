.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__header {
				
				margin-bottom: 20px;
				padding: 15px;
				text-align: center;
				color: #FFF;
				background: var(--primary-color);
				
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				padding: 0 45px 0 0;
				color: var(--primary-color);
				text-align: left;
				background: none;
				}
				
				.results-list-header {
					margin: 0;
					font-size: 16px;
					font-weight: 600;
				}
				
			}
			
			&:first-child {
				&__header {
					padding-top: 10px;
					@media screen and (min-width: 992px) {
					padding-top: 0;
					}
				}
			}
		
		}
		
	}
	
}
