.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__players-list {
				
					&__list-filters {
						
						padding: 10px 15px;
						border-top: 1px solid var(--default-border-color);
						border-bottom: 1px solid var(--default-border-color);
						
						&__content {
							justify-content: right;
						}
						
						&__show-past-players {
							
							&__switcher {
								
								display: inline-flex;
								align-content: center;
								align-items: center;
								
								&__text {
									margin-right: 5px;
									font-size: 12px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
