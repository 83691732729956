.plka-line-and-logo-separator {
	
	margin: 25px 0;
	padding: 0;
	@media screen and (min-width: 992px) {
	margin: 50px 0;
	}
	
	&:last-child {
		margin-bottom: 0;
	}
	
	.plka-line-and-logo {
		
		position: relative;
		padding: 30px 15px;
		
		span.line-separator {
			display: block;
			width: 100%;
			height: 2px;
			border-bottom: 2px solid var(--primary-color);
		}
		
		a.logo-link {
			
			position: absolute;
			top: 0;
			left: calc(50% - 31px);
			width: 62px;
			height: 62px;
			
			&:hover {
				opacity: 0.8;
			}
			
			img {
				width: 62px;
				max-width: 62px;
				height: 62px;
				max-height: 62px;
			}
			
		}
		
		
	}
	
}
