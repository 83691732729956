.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__players-list {
					
					padding: 10px 15px 0;
					background: #FFF;
					border: 1px solid var(--default-border-color);
					
				}
				
			}
			
		}
		
	}
	
}
