.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
						
							&__players-list {
								position: relative;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
