.transfers-list {
	
	&__list {
		
		&__players {
			
			&__list {
				
				&__player {
					
					margin-bottom: 15px;
					padding: 0 15px;
					
					&__image {
						
						text-align: center;
						
						&__link {
							
							display: flex;
							justify-content: center;
							align-items: center;
							align-content: center;
							height: 65px;
							background: #FFF;
							
							@media screen and (min-width: 992px) {
							height: 75px;
							padding: 5px;
							}
							
							&:hover {
								opacity: 0.7;
							}
							
							&__image {
								max-width: 65px;
								max-height: 65px;
							}
							
						}
						
					}
					
					&__name {
						margin-top: 10px;
						text-align: center;
						text-transform: uppercase;
						line-height: 16px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
