.game-result-information-switcher {
	
	margin-bottom: 15px;
	padding: 0 20px;
	//border-top: 1px solid var(--default-border-color);
	//border-bottom: 1px solid var(--default-border-color);
	
	@media screen and (min-width: 992px) {
	margin-bottom: 30px;
	border-top: none;
	border-bottom: none;
	}
	
	.game-result-information-switcher-content {
		justify-content: space-between;
	}
	
	.information-switcher-element {
		
		height: 26px;
		padding: 0;
		
		@media screen and (max-width: 991px) {
			
			&.possessions-element {
				flex: 0 0 26%;
				max-width: 26%;
			}
			
			&.throws-element, &.leaders-element {
				flex: 0 0 16%;
				max-width: 16%;
			}
			
			&.diagrams-element, &.progress-element {
				flex: 0 0 21%;
				max-width: 21%;
			}
			
		}
		
		@media screen and (min-width: 992px) {
		min-width: 11%;
		height: 25px;
		background: url("/Assets/Images/Pages/Games/Game/Result/game_result_switcher_element_bg.png") center center no-repeat;
		background-size: 100% 100%;
		}
		
		span {
			
			display: block;
			padding: 0 10px;
			font-size: 11px;
			color: #FFF;
			text-align: center;
			line-height: 28px;
			cursor: pointer;
			background: var(--secondary-color);
			
			@media screen and (min-width: 992px) {
			padding: 0 25px;
			font-size: 12px;
			line-height: 26px;
			}
			
		}
		
		&:hover, &.active {
			span {
				color: var(--primary-special-color);
			}
		}
		
		&.active {
			font-weight: 600;
			@media screen and (max-width: 991px) {
				span {
					color: #FFF;
					background: var(--primary-special-color);
				}
			}
		}
		
	}
	
	
	
}
