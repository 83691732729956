.game-result-team-logo {
	
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	height: 80px;
	padding: 5px;
	border: 1px solid var(--default-border-color);
	background: #FFF;
	
	@media screen and (min-width: 992px) {
	height: auto;
	min-height: 180px;
	padding: 15px;
	border-width: 5px;
	}
	
	a {
		
		display: block;
		
		img {
			max-width: 100%;
			max-height: 68px;
			@media screen and (min-width: 992px) {
			max-height: 140px;
			}
		}
		
		&:hover {
			opacity: 0.7;
		}
		
	}
	
}
