.team-details {
	
	&__team-information {
		
		&__team-next-games {
			
			&__header {
				
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				line-height: 18px;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 24px;
				}
				
			}
			
		}
		
	}
	
}
