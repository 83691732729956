.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							&__add-player {
								
								margin-top: 10px;
								
								.message-component {
									margin: 10px 15px 0;
									max-width: calc(100% - 30px);
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
