.system-message-component {
	
	margin: 50px 0;
	padding: 0;
	
	.alert-danger {
		color: var(--alert-error-color);
		border: 1px solid var(--alert-error-color);
	}
	
	&.no-margin-top {
		margin-top: 0;
	}
	
	&.no-margin-bottom {
		margin-bottom: 0;
	}
	
	&.no-margin {
		margin: 0;
	}
	
}
