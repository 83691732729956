.single-content-loader {
	
	display: flex;
	padding-top: 10px;
	padding-bottom: 5px;
	justify-content: center;
	align-items: center;
	
	&.bordered {
		border: 2px solid var(--secondary-color);
	}
	
	&.white {
		color: var(--white-color);
		border-color: var(--white-color);
	}
	
	&.start {
		align-self: flex-start;
	}
	
	@for $i from 1 through 1000 {
		
		&.margin-vertical {
			&-#{$i} {
				margin: #{$i}px auto;
			}
		}
		
		&.margin-top {
			&-#{$i} {
				margin-top: #{$i}px;
			}
		}
		
		&.margin-bottom {
			&-#{$i} {
				margin-bottom: #{$i}px;
			}
		}
		
		&.padding {
			padding: 10px;
			&-#{$i} {
				padding: #{$i}px;
			}
		}
		
	}
	
}
