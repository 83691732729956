.alert {
	
	margin: 0;
	background: #FFF;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	border-radius: 0;
	
	&.alert-success {
		color: var(--alert-success-color);
		border-color: var(--alert-success-color);
	}
	
	&.alert-warning {
		color: var(--primary-special-color);
		border-color: var(--primary-special-color);
	}
	
	&.alert-info {
		color: var(--primary-color);
		border-color: var(--primary-color);
	}
	
}
