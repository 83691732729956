.service-sponsors-section {
	
	&__carousel {
		
		&__item {
			
			display: flex!important;
			position: relative;
			overflow: hidden;
			height: 60px;
			padding: 0 15px;
			align-items: center;
			align-content: center;
			justify-content: center;
			
			@media screen and (min-width: 992px) {
			height: 80px;
			padding: 0 25px;
			}
			
			a, span {
				
				display: block;
				
				img {
					max-height: 60px;
					@media screen and (min-width: 992px) {
					max-height: 80px;
					}
				}
				
			}
			
			a:hover {
				opacity: 0.7;
			}
			
		}
	
	}

}
