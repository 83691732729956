.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						padding: 0 15px 0;
						border-bottom: 1px solid var(--default-border-color);
						
						&.opened {
							padding-bottom: 10px;
						}
						
						&:last-child {
							border-bottom: none;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
