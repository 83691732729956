.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__player-add {
					
					margin-top: 10px;
					
					&__form-display-switcher {
					
						&__content {
							justify-content: right;
						}
						
						&__switcher {
							
							display: inline-flex;
							align-content: center;
							align-items: center;
							margin-right: 15px;
							cursor: pointer;
							
							&:hover {
								color: #DA5E15;
							}
							
							&__text {
								font-weight: 600;
							}
							
							&__icon {
								display: inline-flex;
								align-content: center;
								align-items: center;
								margin-left: 5px;
							}
							
						}
					
					}
					
				}
				
			}
			
		}
		
	}
	
}
