.user-profile-page-menu {
	
	padding-right: 35px;
	border-bottom: none;
	
	@media screen and (min-width: 992px) {
	padding-right: 15px;
	}
	
	.user-profile-page-menu-element {
		
		border: 1px solid #CCC;
		border-top: none;
		
		&:first-child {
			border-top: 1px solid #CCC;
		}
		
		.user-profile-page-menu-element-button {

			padding: 5px;
			font-size: 10px;
			cursor: pointer;
			
			@media screen and (min-width: 992px) {
			padding: 15px;
			font-size: 15px;
			}
			
			&:hover {
				color: var(--primary-special-color);
			}
			
			&.selected {
				color: #FFF;
				font-weight: 600;
				background: var(--secondary-color);
			}
			
		}
		
	}
	
}
