.files-for-download {
	
	&__files-groups {
		
		&__file-group {
		
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin-bottom: 20px;
			padding-bottom: 20px;
			}
			
			&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
			}
			
			&__header {
				
				font-size: 12px;
				margin-bottom: 10px;
				text-transform: uppercase;
				font-weight: 600;
				
				@media screen and (min-width: 992px) {
				font-size: 16px;
				}
				
			}
		
		}
		
	}
	
}
