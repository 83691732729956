.section-data {
	
	position: relative;
	margin-bottom: 30px;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid var(--default-border-color);
	}
	
	@media screen and (min-width: 1200px) {
	margin-bottom: 50px;
	padding-bottom: 50px;
	}
	
	&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
	}
	
	.section-data-content {}
	
	.section-data-header {
		
		margin-bottom: 10px;
		padding: 0 0 10px;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--secondary-color);
		border-bottom: 1px solid var(--secondary-color);
		
		@media screen and (min-width: 992px) {
		margin-bottom: 15px;
		padding: 0 0 10px;
		font-size: 16px;
		}
		
		@media screen and (min-width: 1200px) {
		margin-bottom: 20px;
		font-size: 18px;
		}
		
	}
	
	.section-data-row {
		
		margin-bottom: 5px;
		padding: 0;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 10px;
		}
		
		@media screen and (min-width: 1200px) {}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		.btn {
			margin: 0 10px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		
	}
	
}
