.team-details {
	
	&__team-players {
		
		&__list {
			
			&__players {
				
				&__player {
				
					margin-bottom: 10px;
					padding: 0 20px;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 15px;
					padding: 0 25px;
					}
					
					&__image {
						
						display: flex;
						min-height: 100px;
						margin-bottom: 5px;
						justify-content: center;
						align-content: center;
						align-items: center;
						padding: 5px;
						background: #FFF;
						
						@media screen and (min-width: 992px) {
						min-height: 190px;
						margin-bottom: 10px;
						padding: 15px;
						}
						
						a {
							display: block;
							&:hover {
								opacity: 0.7;
							}
						}
						
						img {
							max-height: 160px;
						}
						
					}
					
					&__name {
						
						font-weight: 600;
						text-align: center;
						
						&__captain {
							margin-left: 5px;
							color: #2A2A2A;
						}
						
					}
					
					&__number {
						text-align: center;
					}
				
				}
				
			}
			
		}
		
	}
	
}
