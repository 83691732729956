.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__players-list {
					
					&__players {
						
						position: relative;
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
