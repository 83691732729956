.home-page {
	
	.main-content-loader {
		margin: 25px 0 50px;
	}
	
	&__slider {
	
	}
	
}
