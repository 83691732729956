.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
				
					padding: 0 20px;
					
					@media screen and (min-width: 992px){
					padding: 0 15px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
