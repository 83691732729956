.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
				
				&__group {
					
					&__table {
						
						&__team {
							
							overflow: hidden;
							min-height: 36px;
							max-height: 36px;
							margin-bottom: 5px;
							padding-top: 5px;
							padding-bottom: 5px;
							background: var(--primary-color);
							
							.team-table-position,
							.team-logo,
							.team-name,
							.team-played-games,
							.team-games-balance,
							.team-table-points {
								font-size: 9px;
								color: #FFF;
								line-height: 26px;
								@media screen and (min-width: 992px) {
								font-size: 11px;
								}
							}
							
							.team-table-position,
							.team-logo,
							.team-played-games,
							.team-games-balance,
							.team-table-points {
								text-align: center;
							}
							
							.team-table-position,
							.team-played-games,
							.team-games-balance,
							.team-table-points,
							.team-logo {
								padding: 0;
							}
							
							.team-table-position {
							
							}
							
							.team-logo {
								
								&__link {
									display: block;
								}
								
								&__image {
									max-height: 26px;
								}
								
							}
							
							.team-played-games,
							.team-games-balance,
							.team-table-points,
							.team-name {
								background: var(--secondary-color);
							}
							
							.team-name {
								
								display: flex;
								overflow: hidden;
								height: 26px;
								padding-left: 20px;
								align-items: center;
								align-content: center;
								border-bottom-left-radius: 25px;
								
								&__link {
									
									display: block;
									max-height: 26px;
									color: #FFFFFF;
									line-height: 13px;
									
									&:hover {
										color: var(--primary-special-color);
									}
									
								}
								
							}
							
							.team-table-points {
								font-weight: 600;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
