.game-details-admin {
	
	&__header {

		&__information {
			
			padding: 10px 15px;
			color: #FFF;
			font-size: 12px;
			text-transform: uppercase;
			text-align: center;
			line-height: 30px;
			background: var(--secondary-color);
			
			@media screen and (min-width: 992px) {
			font-size: 14px;
			}
			
			&__game-date, &__game-status {
				font-weight: 600;
			}
			
			&__game-status {
				margin-top: 10px;
				padding-top: 10px;
				color: var(--primary-special-color);
				border-top: 1px solid #FFFFFF;
			}
			
		}
		
	}

}
