.text-page {

	&__content {
		
		@for $i from 1 through 100 {
			
			> ol {
				counter-reset: ol;
				margin-bottom: 30px;
			}
			
			> ol > li {
				margin-bottom: 10px;
				list-style-type: none;
			}
			
			> ol:nth-of-type(n + #{$i}) > li:before {
				counter-increment: ol;
				content: "#{$i + 1}." counter(ol) " ";
			}
			
			ol, ul {
				
				margin-top: 5px;
				
				> li {
					margin-bottom: 5px;
				}
				
			}
			
		}
	
	}

	

}
