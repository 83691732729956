.leagues-switcher {
	
	position: relative;
	margin-bottom: 10px;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 10px;
	}
	
	&--default {
	
	}
	
}
