.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							&__players-list {
								
								&__player {
									
									padding: 10px 15px;
									line-height: 30px;
									background: #FFF;
									
									&:nth-child(odd) {
										background: #EBEBEB;
									}
									
									&__player-image {
										
										a {
											
											display: block;
											
											&:hover {
												opacity: 0.7;
											}
											
											img {
												max-height: 30px;
											}
											
										}
										
									}
									
									&__player-name {
										
										a {
											display: block;
											font-weight: 600;
										}
										
									}
									
									&__player-number {
										
										.form-field {
											margin-bottom: 0;
										}
										
									}
									
									&__player-status {
										
										text-align: center;
										
										&.status-1 {
											color: #1C7430;
										}
										
									}
									
									&__player-actions {
										
										text-align: right;
										
										&__edit-action,
										&__save-action,
										&__remove-action,
										&__cancel-action {
											
											margin-left: 10px;
											cursor: pointer;
											
											&:first-child {
												margin-left: 0;
											}
											
											&:hover {
												color: #DA5E15;
											}
											
											svg {
												width: 20px;
												height: 16px;
											}
										}
										
										&__edit-action {}
										
										&__save-action {
											color: #1C7430;
											&:hover {
												color: #DA5E15;
											}
										}
										
										&__remove-action {
											&.active {
												color: #DA5E15;
												&:hover {
													color: #E2000F;
												}
											}
										}
										
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
