.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__events {
					
					position: relative;
					
				}
				
			}
			
		}
		
	}
	
}
