.home-page {
	
	&__articles {
		
		&__list {
			
			&__article {
				
				flex: 0 0 300px;
				max-width: 300px;
				height: 200px;
				overflow: hidden;
				
				@media screen and (min-width: 768px) {
					padding: 0 15px;
				}
				
				.article-image {
					
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					height: 90px;
					margin-bottom: 10px;
					
					a {
						display: block;
					}
					
					img {
						max-height: 90px;
					}
					
				}
				
				.article-title {
					
					position: relative;
					margin-bottom: 5px;
					padding-right: 30px;
					
					&::before, &::after {
						position: absolute;
						content: "";
						top: 0;
						width: 15px;
						height: 35px;
					}
					
					&::before {
						right: 15px;
						border-top: 35px solid #E5E5E5;
						border-right: 10px solid transparent;
					}
					
					&::after {
						right: 30px;
						border-top: 35px solid transparent;
						border-right: 10px solid #E5E5E5;
					}
					
					.article-title-link {
						
						display: block;
						height: 35px;
						overflow: hidden;
						padding: 5px 25px 5px 15px;
						font-size: 12px;
						color: #FFF;
						text-align: left;
						text-transform: uppercase;
						line-height: 20px;
						background: var(--primary-color);
						border-bottom: 5px solid #E5E5E5;
						
						span {
							display: block;
						}
						
						&:hover {
							color: var(--primary-special-color);
						}
						
					}
					
				}
				
				.article-content {
					
					overflow: hidden;
					height: 60px;
					font-size: 11px;
					line-height: 15px;
					text-align: left;
					color: #000;
					
				}
				
			}
			
		}
		
	}
	
}
