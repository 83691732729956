.team-details {
	
	&__team-information {
		
		&__team-top-players {
			
			&__list {
				
				&__players {
					
					&__player {
						
						position: relative;
						padding: 5px 5px;
						line-height: 18px;
						
						@media screen and (min-width: 992px) {
						padding: 5px 15px;
						}
						
						&:nth-child(even) {
							background: #EBEBEB;
						}
						
						&__position, &__image, &__name, &__score {
							display: inline-flex;
							align-items: center;
							align-content: center;
						}
						
						&__position, &__score {
							font-weight: 600;
							text-align: center;
							justify-content: center;
						}
						
						&__image {
							
							text-align: center;
							justify-content: center;
							
							img {
								max-height: 26px;
								@media screen and (min-width: 992px) {
								max-height: 36px;
								}
							}
							
							a {
								display: block;
								&:hover {
									opacity: 0.7;
								}
							}
							
						}
						
						&__name {
							text-transform: uppercase;
						}
						
						&__score {
							justify-content: right;
							font-size: 14px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
