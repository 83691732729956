.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							&__add-player {
								
								margin-top: 10px;
								
								&__header {
									
									text-align: right;
									
									&__switcher {
										
										display: inline-flex;
										align-items: center;
										align-content: center;
										font-weight: 600;
										cursor: pointer;
										
										&:hover {
											color: #DA5E15;
										}
										
										&__text, &__icon {
											display: inline-flex;
											align-items: center;
											align-content: center;
										}
										
										&__icon {
											margin-left: 5px;
										}
										
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
