.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
				
				&__group {
					
					&__header {
						margin-bottom: 5px;
						padding: 0;
						color: #222;
						font-weight: 600;
					}
					
				}
				
			}
			
		}
		
	}
	
}
