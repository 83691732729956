.game-information-tables-team {
	
	margin-top: 25px;
	padding: 0 20px;
	@media screen and (min-width: 992px) {
	margin-top: 50px;
	padding: 0 15px;
	}
	
	&:first-child {
	margin-top: 0;
	}
	
}
