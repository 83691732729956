.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
					
					&__information {
						
						&__name {
							
							padding: 10px 15px;
							background: var(--secondary-color);
							border-left-width: 15px;
							border-left-style: solid;
							
							&__content {
								padding: 0 15px 0 10px;
								justify-content: center;
							}
							
							a {
								
								display: block;
								color: #FFFFFF;
								line-height: 15px;
								
								&:hover {
									color: var(--primary-special-color);
								}
								
							}
							
						}
						
					}
					
				}
				
			}
		
		}

	}

}
