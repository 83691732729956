.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
			
				margin-bottom: 10px;
				padding-left: 25px;
				padding-right: 20px;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				padding-right: 15px;
				}
			
			}
			
		}
		
	}
	
}
