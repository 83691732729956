.game-result-details {
	
	&__progress {
		
		&__header {
			
			&__team {
				
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				position: relative;
				padding: 5px 15px;
				background: var(--secondary-color);
				border-bottom-color: #017A3B;
				border-bottom-width: 5px;
				border-bottom-style: solid;
				
				@media screen and (min-width: 992px) {
				padding: 10px 15px;
				border-bottom-width: 10px;
				}
				
				&::after, &::before {
					
					display: none;
					content: "";
					position: absolute;
					top: 0;
					width: 15px;
					height: 50px;
					z-index: 10;
					
					@media screen and (min-width: 992px) {
					display: block;
					}
					
				}
				
				&.home-team {
					
					&::after {
						right: -15px;
						border-top: 50px solid #017A3B;
						border-color: inherit;
						border-right: 15px solid transparent;
					}
					
					&::before {
						right: 0;
						border-bottom: 50px solid #017A3B;
						border-color: inherit;
						border-left: 15px solid transparent;
					}
					
				}
				
				&.away-team {
					
					&::after {
						left: 0;
						border-bottom: 50px solid #017A3B;
						border-color: inherit;
						border-right: 15px solid transparent;
					}
					
					&::before {
						left: -15px;
						border-top: 50px solid #017A3B;
						border-color: inherit;
						border-left: 15px solid transparent;
					}
					
				}
				
				.team-name {
					
					display: block;
					font-size: 12px;
					text-align: center;
					color: #FFF;
					line-height: 20px;
					
					@media screen and (min-width: 992px) {
					font-size: 18px;
					font-weight: 600;
					}
					
					&:hover {
						color: var(--primary-special-color);
					}
				}
				
			}
		
		}
		
	}
	
}
