.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__header {
					
					margin-bottom: 5px;
					font-size: 14px;
					color: var(--primary-color);
					border-bottom: 3px solid var(--default-border-color);
					
					&__content {
						
						justify-content: center;
						
						@media screen and (min-width: 992px) {
						padding: 0 15px 0 0;
						justify-content: left;
						}
						
						span {
							
							display: block;
							width: 100%;
							position: relative;
							padding: 5px 20px 5px 10px;
							color: #FFF;
							line-height: 24px;
							background: var(--secondary-color);
							border-bottom: 3px solid var(--primary-special-color);
							
							@media screen and (min-width: 992px) {
							width: auto;
							margin: 0;
							}
							
							&::before, &::after {
								display: none;
								content: "";
								position: absolute;
								top: 0;
								height: 37px;
								@media screen and (min-width: 992px) {
								display: block;
								}
							}
							
							&::before {
								right: -10px;
								border-top: 37px solid var(--primary-special-color);
								border-right: 10px solid transparent;
							}
							
							&::after {
								right: 0;
								border-top: 37px solid transparent;
								border-right: 10px solid var(--primary-special-color);
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
