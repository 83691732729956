.header-user-zone {
	
	margin-top: 15px;
	padding: 0;
	text-align: center;
	
	@media screen and (min-width: 992px) {
	position: absolute;
	top: -13px;
	right: 7.333333%;
	margin-top: 0;
	text-align: right;
	z-index: 100000;
	}
	
	@media screen and (min-width: 1366px) {
	right: 6.333333%;
	}
	
	a, .logout-button {
		
		display: inline-block;
		margin-top: 2px;
		font-size: 20px;
		color: #FFF;
		
		&:hover, &.active {
			color: var(--primary-special-color);
		}
		
		svg {
			vertical-align: top;
		}
		
	}
	
	.logout-button {
		margin-left: 10px;
		cursor: pointer;
	}
	
}
