.transfers-list {
	
	&__list {
		
		&__players {
			
			&__list {
				
				position: relative;
				
			}
			
		}
		
	}
	
}
