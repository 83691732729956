.game-tables-team-table-stats {
	
	height: 25px;
	color: #000;
	line-height: 25px;
	background: var(--default-border-color);
	border-top: 2px solid #E1E4E4;
	border-bottom: 2px solid #E1E4E4;
	
	@media screen and (min-width: 992px) {
	padding: 0;
	height: 40px;
	line-height: 40px;
	}
	
	.table-row-cell {
		
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		line-height: 28px;
		background: var(--default-border-color);
		
		@media screen and (min-width: 992px) {
		height: 100%;
		font-size: 12px;
		line-height: 50px;
		}
		
		&.team-name {
			font-size: 10px;
			@media screen and (min-width: 992px) {
			font-size: 14px;
			border-right: none;
			}
		}
		
		&.team-rebounds, &.pt1-percentage-throws, &.pt1-percentage-throws,
		&.team-summary-blocks, &.team-summary-turnovers, &.team-summary-fouls {
			min-height: 25px;
			border-right: 2px solid #E1E4E4;
			@media screen and (min-width: 992px) {
			min-height: 40px;
			}
		}
		
		&.team-number, &.team-points, &.team-minutes,
		&.team-throws, &.team-assists, &.team-summary-eval,
		&.team-summary-steals, &.team-summary-blocks, &.team-summary-effectivity {}
		
		.statistic-label, .statistic-value {
			display: block;
		}
		
		.statistic-label {
			width: 100%;
			color: #FFF;
			background: var(--secondary-color);
			@media screen and (min-width: 992px) {
			display: none;
			}
		}
		
		.statistic-value {
			font-weight: 600;
		}
		
	}
	
}
