.leagues-switcher {
	
	
	&__leagues-list {
		
		position: relative;
		
		&__content {
			justify-content: space-between;
		}
		
	}
	
	
	&--default {
		
		&__leagues-list {
			
			&__content {
				margin: 0;
				justify-content: left;
			}
			
		}
		
	}
	
}
