.form-control {
	
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	
	&.error {
		border-color: var(--default-error-color);
	}
	
	&:focus, &:active {
		outline: none;
		box-shadow: none;
		border-color: var(--primary-color);
	}
	
	&::placeholder {
		text-transform: uppercase;
		font-size: 12px;
	}
	
}


input[type="file"] {
	color: transparent;
}


textarea {
	min-height: 100px;
}


.form-label {
	
	font-weight: 600;
	text-transform: uppercase;
	
	&.error {
		width: 100%;
		margin-top: 5px;
		justify-content: center;
		text-align: center;
	}
	
}


.btn {
	
	height: 45px;
	padding: 10px 30px;
	text-transform: uppercase;
	outline: none!important;
	box-shadow: none!important;
	
	&.btn-primary {
		padding: 10px 50px;
		background: var(--primary-color);
		border: none;
		&:hover {
			background: var(--primary-special-color);
		}
	}
	
	&.small-button, &.btn-sm {
		height: 30px;
		padding: 5px 10px;
		font-size: 12px;
	}
	
}


span.data-label, a.data-label {
	font-size: 10px;
	@media screen and (min-width: 992px) {
		font-size: 12px;
	}
}

span.data-value, a.data-value {
	
	font-weight: 600;
	font-size: 11px;
	
	@media screen and (min-width: 992px) {
		font-size: 12px;
	}
	
	&:nth-child(n + 1) {
		margin-left: 10px;
	}
	
}

span.data-checkbox {
	
	display: inline-block;
	vertical-align: top;
	width: 20px;
	height: 20px;
	text-align: center;
	background: var(--white-color);
	border: 1px solid var(--default-border-color);
	cursor: pointer;
	
	@media screen and (min-width: 992px) {
	width: 22px;
	height: 22px;
	}
	
	@media screen and (min-width: 1200px) {
	width: 24px;
	height: 24px;
	}
	
	&:last-child {
		margin-left: 10px;
	}
	
	&:checked {
		background: var(--default-border-color);
	}
	
	svg {
		width: 18px;
		height: 18px;
		margin: 2px;
		color: var(--secondary-color);
		vertical-align: top;
	}
	
}


@import "@Assets/@Styles/SCSS/Elements/Forms/User/index.scss";
