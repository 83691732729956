.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
			
				margin-bottom: 20px;
				//padding-top: 20px;
				border-top: 2px solid var(--default-border-color);
				
				@media screen and (min-width: 992px) {
				position: absolute;
				top: 100px;
				width: 22%;
				max-width: 22%;
				flex: 0 0 23%;
				margin-bottom: 0;
				padding: 0 15px 0 15px;
				border-top: none;
				}
				
				&__content {
					@media screen and (min-width: 992px) {
					margin: 0 -15px;
					}
				}
			
			}
			
		}
		
	}
	
}
