.checkbox-form-field {
	
	line-height: 20px;
	
	&__content {}
	
	&__input {
		
		padding: 0;
		
		.checkbox {
			
			display: inline-flex;
			width: 20px;
			height: 20px;
			align-items: center;
			text-align: center;
			justify-content: center;
			vertical-align: top;
			background: #FFF;
			border: 1px solid var(--default-border-color);
			cursor: pointer;
			
			svg {
				color: #1C7430;
				width: 14px;
				height: 14px;
				vertical-align: middle;
			}
			
			&.checked {}
			
			&.disabled {
				background: var(--default-border-color);
				cursor: not-allowed;
			}
			
		}
		
	}
	
	&__label {
		
		padding-left: 10px;
		
		label.form-label {
			margin-bottom: 0!important;
			padding-right: 0!important;
		}
		
	}
	
	&__legend {
		
		margin-top: 5px;
		padding: 0;
		font-size: 9px;
		line-height: 18px;
		
	}
	
	label.error {
		font-size: 11px!important;
	}
	
}
