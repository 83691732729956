.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__events {
				
					&__event {
						
						&__event-content {
							
							&__points-and-time {
								
								&__content {
									height: 100%;
								}
								
								&__points,
								&__time {
									
									padding: 5px 15px;
									
									&__content {
										justify-content: center;
									}
									
								}
								
								&__points {
									
									&__content {
										height: 100%;
										align-items: center;
										align-content: center;
									}
									
									font-size: 16px;
									
									&.home-team-points {
										border-left: 2px solid #E1E4E4;
									}
									
									&.away-team-points {
										border-right: 2px solid #E1E4E4;
									}
									
									&.winning {
										color: #1C7430;
									}
									
								}
								
								&__time {
									
									font-size: 11px;
									
									@media screen and (min-width: 992px) {
									font-size: 13px;
									}
									
									&__content {
										height: 100%;
										align-items: center;
										align-content: center;
									}
									
									
									padding-top: 10px;
									padding-bottom: 10px;
									border-left: 2px solid #E1E4E4;
									border-right: 2px solid #E1E4E4;
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
