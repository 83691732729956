.leagues-schedules {
	
	&__league-rounds-list {

		&__league_round {

			position: relative;
			padding: 0 15px;
			border-bottom: 1px solid var(--primary-color);
			
			@media screen and (min-width: 992px) {
			padding: 50px 15px;
			border-bottom-width: 1px;
			}
			
			&:last-child {
			border-bottom: none;
			}
			
		}
		
	}
	
}
