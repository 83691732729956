.team-details {
	
	&__team-statistics {
		
		position: relative;
		margin: 0;
		padding: 0 5px;
		background: var(--primary-color);
		
		@media screen and (min-width: 992px) {
		padding: 5px 15px;
		color: #FFF;
		text-transform: uppercase;
		border-bottom: 2px solid var(--secondary-color);
		}
		
		&__content {
			padding: 10px 0;
		}
		
		&__statistics {
			
			position: relative;
			
			&__statistic {
				
				display: inline-flex;
				flex-direction: column;
				padding: 5px 3px;
				justify-content: center;
				align-items: center;
				align-content: center;
				font-size: 9px;
				color: #FFF;
				text-transform: uppercase;
				line-height: 18px;
				border-right: 1px solid #FFF;
				
				&:last-child {
					border-right: none;
				}
				
				@media screen and (min-width: 992px) {
				width: 20%;
				flex: 0 0 20%;
				max-width: 20%;
				padding: 10px 15px;
				font-size: 12px;
				line-height: 24px;
				border-right: 2px solid #FFF;
				}
				
				.statistic-label, .statistic-value {}
				
				.statistic-value {
					font-size: 14px;
					font-weight: 600 ;
					@media screen and (min-width: 992px) {
					font-size: 16px;
					}
				}
				
			}
			
		}
		
	}
	
}
