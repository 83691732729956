.game-result-main-information {
	
	padding: 0 20px;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 30px;
	padding: 0 15px;
	}
	
	#upload-game-data {
		display: none;
		position: absolute;
		top: -55px;
		left: -15px;
		svg {
			vertical-align: top;
			margin-top: 4px;
			margin-left: 5px;
		}
	}
	
	.game-result-team-logo,
	.game-result-team-points,
	.game-result-quarters {}
	
}
