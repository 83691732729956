.league-tables {

	margin-top: 20px;
	margin-bottom: 20px;
	padding: 0 20px;
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 15px;
	}

}
