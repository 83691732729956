.free-agent-requests {
	
	&__list {
		
		position: relative;
		
		&__element {
			
			padding: 5px 15px;
			
			&:nth-child(odd) {
				background: #E6E6E6;
			}
			
			&--header {
				color: #FFF;
				background: var(--primary-color)!important;
			}
			
			&.status-open {
				font-weight: 600;
				border: 1px solid #1C7430;
				border-top: none;
			}
			
			&__request-status,
			&__request-date {
				text-align: center;
			}
			
			&__request-status {
			
				&.status-1 {
					color: #1C7430;
				}
				
				&.status-3 {
					color: var(--secondary-color);
				}
				
				&.status-4 {
					color: #E2000F;
				}
				
			}
			
			&__request-actions {
				
				text-align: right;
				
				&__action {
					
					margin-left: 5px;
					vertical-align: top;
					cursor: pointer;
					
					&:first-child {
						margin-left: 0;
					}
					
					svg {
						width: 16px;
						height: 16px;
						margin-top: 4px;
						vertical-align: top;
					}
					
					&--approve {
						color: #1C7430;
					}
					
					&--reject {
						color: var(--primary-special-color);
						&.active {
							color: #E2000F;
						}
					}
					
					&:hover {
						color: var(--secondary-color);
					}
					
				}
				
			}
			
		}
		
	}
	
}
