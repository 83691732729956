.leagues-schedules {
	
	&__league-rounds-list {
		
		margin: 0;
		text-transform: uppercase;
		
		@media screen and (min-width: 992px) {
		padding-bottom: 0;
		}
		
		&__content {
			@media screen and (min-width: 992px) {
			background: url("/Assets/Images/Backgrounds/plka_bg_grey.png") 100% 100% no-repeat;
			background-size: contain;
			}
		}
		
	}
	
}
