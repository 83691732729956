.team-details {
	
	&__team-statistics {
		
		&__games {
			
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--white-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
			}
		
			&__game {
				
				color: var(--white-color);
				text-align: center;
				
				@media screen and (min-width: 992px) {
				border-left: 1px solid var(--white-color);
				}
				
				&__label {}
				
				&__data {
					
					padding: 0 10px;
					
					&__game-link {
						
						display: block;
						line-height: 20px;
						color: var(--white-color);
						
						&:hover {
							color: var(--primary-special-color);
						}
						
						&__opponent-name {
							display: block;
							font-weight: 600;
						}
						
						&__points {
							display: block;
						}
						
					}
					
					&__game-date {
						line-height: 20px;
					}
					
				}
				
			}
		
		}
		
	}
	
}
