.team-details {
	
	&__team-information {
		
		&__team-top-players {
			
			&__header {
				
				margin-bottom: 5px;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				}
				
				&__title {
					font-weight: 600;
				}
				
				&__switcher {
					
					text-align: right;
					
					.statistic-type-switcher {
						
						cursor: pointer;
						
						&.active {
							font-weight: 600;
							cursor: default;
						}
						
					}
					
					.statistics-type-separator {
						margin: 0 5px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
