.game-result-details {
	
	&__diagrams {
		
		&__header {
			
			position: relative;
			margin-bottom: 20px;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 30px;
			}
			
			.diagrams-header {
				
				padding: 0;
				font-size: 16px;
				
				@media screen and (min-width: 992px) {
				font-size: 20px;
				font-weight: 600;
				}
				
				span {
					padding-bottom: 5px;
					border-bottom: 2px solid var(--secondary-color);
					@media screen and (min-width: 992px) {
					border-bottom-width: 5px;
					}
				}
				
			}
			
		}
	
	}
	
}
