.team-details {
	
	&__team-information {
		
		&__team-top-players {
			
			position: relative;
			margin-top: 10px;
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding-right: 30px;
			}
		
			.system-message-component {
				margin-top: 0;
			}
			
		}
		
	}
	
}
