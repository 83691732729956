.global-message-popup {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background: rgba(0,0,0,0.3);
	
	.global-message-popup-container {
		
		height: 100%;
		
		.global-message-popup-content {
			
			height: 100%;
			align-items: center;
			align-content: center;
			justify-content: center;
			
			.global-message-popup-message {
				
				position: relative;
				min-width: 300px;
				padding: 15px;
				text-align: center;
				font-weight: 600;
				background: #FFF;
				border: 2px solid #CCC;
				border-radius: 8px;
				box-shadow: 2px 3px 5px var(--silver-color);
				
				&.error {
					color: var(--default-error-color);
					border-color: var(--default-error-color);
				}
				
				&.success {
					color: var(--default-success-color);
					border-color: var(--default-success-color);
				}
				
				.close-popup {
					
					position: absolute;
					top: -10px;
					right: -10px;
					width: 20px;
					height: 20px;
					cursor: pointer;
					z-index: 100;
					
					&:hover {
						color: var(--primary-special-color);
					}
					
					svg {
						width: 18px;
						height: 18px;
						margin: 1px;
						vertical-align: top;
					}
					
				}
				
			}
			
		}
		
	}
	
}
