.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
					
					&__image {
						
						margin-bottom: 10px;
						padding: 5px;
						background: #FFF;
						border: 1px solid var(--default-border-color);
						
						&__content {
							justify-content: center;
						}
						
						a {
							display: block;
							&:hover {
								opacity: 0.7;
							}
						}
						
						img {
							
							max-height: 80px;
							
							@media screen and (min-width: 992px) {
							max-height: 110px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
