.text-page {
	
	&__content {
		
		h2 {
			margin-bottom: 50px;
			font-size: 22px;
		}
		
		h3 {
			margin-bottom: 40px;
			font-size: 18px;
		}
		
		h4 {
			margin-bottom: 30px;
			font-size: 16px;
		}
		
		h5 {
			margin-bottom: 20px;
			font-size: 14px;
		}
		
		p {
			margin-bottom: 20px;
			line-height: 20px;
		}
		
		table {
			
			width: 100%;
			
			td {
				
				padding: 5px 10px;
				
				p {
					margin-bottom: 0;
				}
				
			}
			
		}
		
	}
	
}
