.transfers-list {
	
	&__list {
		
		&__players {
			
			&__header {
				
				margin-bottom: 10px;
				font-weight: 600;
				text-transform: uppercase;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 20px;
				font-size: 14px;
				}
				
			}
			
		}
		
	}
	
}
