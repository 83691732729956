.game-result-details {
	
	&__progress {
		
		&__header {
			
			&__separator {
				
				display: flex;
				justify-content: center;
				align-content: flex-end;
				align-items: flex-end;
				padding-bottom: 5px;
				font-size: 12px;
				font-weight: 600;
				color: #1A1A18;
				border-top: 1px solid #CBCBCB;
				
				@media screen and (min-width: 992px) {
				padding-bottom: 10px;
				font-size: 18px;
				}
				
			}
			
		}
		
	}
	
}
